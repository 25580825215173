<template>
  <div class="list-user admin_container_default">
    <div class="list-user-table cpx-4">
      <div class="mb-2 row p-0 admin_header">
        <div class="d-flex p-0 admin_title">
          <div class="text-truncate-mess header-font font-weight-bold">
            単発ページ管理
          </div>
        </div>
        <div class="py-2 admin_center">
          <b-form-input
            class="m-0"
            placeholder="検索"
            v-model="keywords"
            v-on:keyup="search()"
          ></b-form-input>
        </div>
        <div class="tex-left text-md-right text-sm-right admin_right">
          <button v-on:click="createFixed()" class="button-create-user">
            新規作成
          </button>
          <button v-on:click="gotoDashboard()" class="button-back-dashboard">
            戻る
          </button>
        </div>
      </div>
      <div class="row w-100 d-flex">
        <!-- <div class="row-input w-100">
          <label class="col-12 mx-3 font-weight-bold">カテゴリ</label>
          <div class="col-12 cp-4">
            <b-form-select
              class="w-75 m-0"
              id="select-cate"
              v-model="type"
              :options="listCategoryOptions"
            ></b-form-select>
          </div>
        </div> -->
      </div>
      <!-- <div class="d-flex justify-content-center mb-4">
        <b-button
          v-on:click.prevent="search()"
          variant="info"
          class="mr-4"
          :disabled="isLoading"
        >
          <span v-show="!isLoading"> 絞り込み検索 </span>
          <div
            v-show="isLoading"
            class="spinner-border spinner-border-sm"
            role="status"
          >
            <span class="sr-only">ローディング...</span>
          </div>
        </b-button>
        <b-button variant="secondary" class="mr-4" v-on:click="reset()"
          >リセット</b-button
        >
      </div> -->
      <Tables
        :items="listFixedPageData"
        :fields="fields"
        :itemsPerPageSelect="{}"
        class="cpx-15"
      >
        <template v-slot:id="{ index }">
          <td>{{ index + 1 }}</td>
        </template>
        <template v-slot:title="{ item }">
          <td style="min-width: 100px; width: 15%" class="py-2">
            <div class="text-truncate-mess">
              {{ item.title }}
            </div>
          </td>
        </template>
        <template v-slot:updated_at="{ item }">
          <td style="min-width: 145px; width: 15%" class="py-2">
            <div class="text-truncate-mess">
              {{
                item.updated_at &&
                new Date(item.updated_at).toLocaleString("ja-JP", {
                  timeZone: "Asia/Tokyo",
                })
              }}
            </div>
          </td>
        </template>
        <template v-slot:action="{ item, index }">
          <td class="py-2 pl-2 pr-1 d-flex">
            <div
              class="w-5 d-flex justify-content-center align-items-center"
              v-if="
                (typeUser == type_cdea && item.type == 'フリー入力') ||
                item.type != 'フリー入力'
              "
            >
              <CButton
                class="mx-1 btn-other mb-0"
                color="primary bg-0083cf"
                square
                variant="outline"
                size="sm"
                v-on:click="copyUrl(item.id)"
              >
                タグをコピー
              </CButton>
              <CButton
                class="mx-1 btn-other mb-0"
                color="success"
                v-on:click="edit(item.id, index)"
                square
                variant="outline"
                size="sm"
                v-b-modal.modal-comfirm
              >
                編集
              </CButton>
              <CButton
                class="mx-1 btn-del"
                color="danger"
                square
                v-on:click="showConfirm(item.id)"
                variant="outline"
                size="sm"
                v-b-modal.modal-block-fixed
              >
                削除
              </CButton>
            </div>
          </td>
        </template>
      </Tables>
    </div>
    <div>
      <b-modal id="modal-block-fixed" hide-header hide-footer>
        <div class="d-block text-center">
          <h5>ページが削除されてもよろしいですか？</h5>
        </div>
        <div class="d-flex justify-content-center">
          <b-button
            class="mx-2 mt-3 btn btn-danger"
            block
            :disabled="isLoadingBlock"
            v-on:click.prevent="blockFixedPage(dataModal)"
          >
            <b-spinner v-if="isLoadingBlock" small></b-spinner>
            削除
          </b-button>
          <b-button
            class="mx-2 mt-3 btn btn-secondary"
            block
            :disabled="isLoadingBlock"
            @click="$bvModal.hide('modal-block-fixed')"
          >
            キャンセル
          </b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Tables from "../../components/common/Tables";
import { tableFields } from "../../utils/table-fields.js";
import { mapActions, mapGetters } from "vuex";
import { Constants } from "../../utils/constants";
export default {
  name: "listFixedPage",
  data() {
    return {
      fields: tableFields.FIXED_PAGE,
      dataModal: "",
      type: "",
      limit: 10,
      page: 1,
      listCategoryOptions: Constants.CATEGORY_FIXED_PAGE,
      isLoading: false,
      typeUser: localStorage.getItem(Constants.USER_TYPE_ADMIN),
      type_cdea: Constants.USER_TYPE_DEFAULT["cdea"],
      isLoadingBlock: false,
      // shop_id: this.$route.params.shopId,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      keywords: null,
      listFixedPageData: null,
    };
  },
  components: {
    Tables,
  },
  created() {
    const request = {
      shop_id: this.shop_id,
      domain: Constants.DOMAIN,
      type: this.type,
      keywords: this.keywords,
    };
    this.getListFixed(request);
  },
  computed: {
    ...mapGetters(["listFixedPage", "message", "success", "error"]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
        this.isLoading = false;
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    listFixedPage() {
      this.listFixedPageData = this.listFixedPage;
      this.isLoading = false;
    },
  },
  methods: {
    ...mapActions({ getListFixed: "getListFixed" }),
    ...mapActions({ deleteFixedPage: "deleteFixedPage" }),
    createFixed() {
      this.$router.push({
        name: this.$route.params.shopId
          ? "createFixedPage"
          : "createFixedPage domain",
      });
    },
    edit(id, index) {
      localStorage.setItem(Constants.NO_FIXED_PAGE, index + 1);
      if (this.$route.params.shopId) {
        this.$router.push({
          name: "editFixedPage",
          params: { shopId: this.shop_id, id: id },
        });
      } else {
        this.$router.push({
          name: "editFixedPage domain",
          params: { id: id },
        });
      }
    },
    gotoDashboard() {
      if (this.$route.params.shopId) {
        this.$router.push({ name: "DashboardAdminCreate" });
      } else {
        this.$router.push({ name: "DashboardAdminCreate domain" });
      }
    },
    search() {
      this.isLoading = true;
      // const formData = {
      //   shop_id: this.shop_id,
      //   domain: Constants.DOMAIN,
      //   type: this.type,
      //   keywords: this.keywords,
      // };
      // this.getListFixed(formData);
      this.listFixedPageData = this.listFixedPage.filter(
        (page) =>
          page.title.includes(this.keywords) ||
          page.type.includes(this.keywords)
      );
    },
    reset() {
      this.type = "";
      const formData = {
        shop_id: this.shop_id,
        domain: Constants.DOMAIN,
        type: this.type,
        keywords: this.keywords,
      };
      this.getListFixed(formData);
    },
    showConfirm(id) {
      this.dataModal = id;
    },
    async blockFixedPage(id) {
      this.isLoadingBlock = true;
      const data = {
        shop_id: this.shop_id,
        id: id,
      };
      const dataReturn = await this.$store.dispatch("deleteFixedPage", data);
      if (dataReturn.success) {
        this.$nextTick(() => {
          this.$bvModal.hide("modal-block-fixed");
          const formData = {
            shop_id: this.shop_id,
            type: this.type,
          };
          this.getListFixed(formData);
          this.isLoadingBlock = false;
        });
      } else {
        this.isLoadingBlock = false;
      }
    },
    copyUrl(id) {
      const hostName = window.location.origin;
      var href = "";
      // if (type != Constants.FIXED_PAGE_TYPE["column_page"]) {
      href = this.$router.resolve({
        name: this.$route.params.shopId ? "staticPage" : "staticPage domain",
        params: { id: id },
      }).href;
      // } else {
      //   href = this.$router.resolve({
      //     name: this.$route.params.shopId ? "pageColumn" : "pageColumn domain",
      //     params: { id: id },
      //   }).href;
      // }
      // href = this.$route.params.shopId
      //   ? "/shop/" + this.$route.params.shopId + "/fixed-page/" + token
      //   : "/fixed-page/" + token;
      var copyText = hostName + href;
      navigator.clipboard.writeText(copyText);
      this.$store.commit("set", ["message", Constants.MESSAGE_COPY]);
      this.$store.commit("set", ["success", true]);
    },
  },
};
</script>
